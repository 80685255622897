import React from 'react';
import Icon from '@material-ui/core/Icon';
import history from 'toolbox/history';
import './_style.scss';

export default ({ link, icon, text }) => {
    return (
        <div className="link-block" onClick={() => history.push(link)}>
            <Icon>{icon}</Icon>
            <strong className="pl-2">{text}</strong>
        </div>
    );
};
