import axios from 'axios';
// import theme from '../theme';

let baseURL;

// switch (process.env.REACT_APP_API_URL ?? theme.site.storeName) {
//     case 'supercellars':
//         baseURL = 'https://supercellars.mmgweb.com.au/';
//         break;

//     case 'liquorwarehouse':
//         baseURL = 'https://waymark.mmgweb.com.au/';
//         break;

//     case 'victoriapark':
//         baseURL = 'https://supercellars-api.mthreemedia.com.au/';
//         break;

//     default:
//         baseURL = 'http://supercellars-api.test/';
// }

switch(window.location.hostname){
    case 'localhost':
        baseURL = 'http://supercellars-api.test/';
        break;
    case 'liquorwarehouse.com.au':
        baseURL = 'https://waymark.mmgweb.com.au';
        break;
    case 'banksia.shop.supercellars.com.au':
        baseURL = 'https://supercellars.mmgweb.com.au/';
        break;
    case 'supercellars-react.mthreemedia.com.au':
        baseURL = 'http://supercellars-api.mthreemedia.com.au/';
        break;
    default:
        baseURL = 'http://supercellars-api.mthreemedia.com.au/';
}

const instance = axios.create({
    baseURL: baseURL
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('web_user_token');
        config.headers.Authorization = `Bearer ${token ? token : 'None'}`;
        config.data = { ...config.data, whoIsRequesting: 'web' };
        return config;
    },
    (err) => {
        console.log('ERROR', err);
        return Promise.reject(err);
    }
);

export default instance;
