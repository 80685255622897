import { createMuiTheme } from '@material-ui/core/styles';

import 'scss/index.scss';
import 'scss/themes/liquorwarehouse.scss';

// import { SystemUpdate } from '@material-ui/icons';
// liquorwarehouse
// supercellars
// victoriapark
let theme = 'liquorwarehouse';

switch (theme) {
    case 'victoriapark':
        theme = createMuiTheme({
            menuTitle: '',
            firstGrid: '',
            secondGrid: 'contact',
            topBar: {
                display: true,
                simple: false
            },
            footer: {
                showDesigner: true,
                extraLink: []
            },
            popup: false,
            showCatalogue: false,
            demo: true,
            banner: {
                fullWidth: true,
                height: '30%'
            },
            loader: {
                name: 'circle'
            },
            nav: {
                navSpecialType: 'static-image',
                slogan: '',
                subCatType: 'image'
            },
            site: {
                storeName: 'victoriapark'
            },
            home: {
                sections: [false, '', '']
            }
        });
        break;

    case 'supercellars':
        theme = createMuiTheme({
            menuTitle: 'YOUR TASTE. OUR SPECIALTY.',
            firstGrid: 'logo',
            secondGrid: 'store',
            topBar: {
                display: false,
                simple: false
            },
            footer: {
                showDesigner: true,
                extraLink: []
            },
            popup: false,
            showCatalogue: false,
            loader: {
                name: 'liquor'
            },
            nav: {
                navSpecialType: 'slider',
                slogan: 'YOUR TASTE. OUR SPECIALTY.',
                subCatType: 'text'
            },
            site: {
                storeName: 'supercellars'
            }
        });
        break;

    case 'liquorwarehouse':
        theme = createMuiTheme({
            menuTitle: 'YOUR TASTE. OUR SPECIALTY.',
            firstGrid: '',
            secondGrid: 'contact',
            topBar: {
                display: true,
                simple: true
            },

            banner: {
                fullWidth: false,
                height: null
            },
            popup: true,
            footer: {
                showDesigner: false,
                extraLink: [
                    ["/rewards", "Liquor Warehouse Rewards"]
                ]
            },
            showCatalogue: true,
            loader: {
                name: 'liquor'
            },
            nav: {
                navSpecialType: 'slider',
                slogan: 'YOUR TASTE. OUR SPECIALTY.',
                subCatType: 'text'
            },
            home: {
                sectionName: [`MULTI-BUY OFFERS`]
            },
            site: {
                storeName: 'liquorwarehouse'
            }
        });
        break;

    default:
}

export default theme;
