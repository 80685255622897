import { useDispatch } from 'react-redux';
import { saveToken } from 'toolbox/Token';
import api from 'apis/api';
import { INIT_WEB, INIT_STATUS } from 'actions/types';

export default () => {
    const dispatch = useDispatch();

    const initWeb = async () => {
        dispatch({ type: INIT_STATUS, payload: true });
        const response = await api.post('api/v1/initial', {});
        dispatch({ type: INIT_WEB, payload: response.data });
        saveToken(response.data.auth);
        dispatch({ type: INIT_STATUS, payload: false });
    };

    return [initWeb];
};
