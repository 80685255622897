import React from 'react';
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';
import { Link } from 'react-router-dom';
import { currencyFormat } from 'toolbox/CurrencyHelper';
import { connect } from 'react-redux';
import CartLineItem from './CartLineItem';
import Button from 'react-bootstrap/Button';

import './_cart-dropdown.scss';

const CartDropDown = ({ order, lineItems, adjustments }) => {
    const orderTotal = order ? order.total : 0;
    const lineItemQty = lineItems ? Object.keys(lineItems).length : 0;

    const renderCartBody = () => {
        if (order && lineItems) {
            return (
                <>
                    <div id="cart-dropdown-item">
                        {Object.keys(lineItems).map((variantId, index) => {
                            return <CartLineItem key={index} item={lineItems[variantId]} adj={adjustments[lineItems[variantId].id]} />;
                        })}
                    </div>
                    <div id="btn-view-cart">
                        {lineItems ? (
                            <>
                                <Link
                                    to="/cart"
                                    className="btn mt-3 pull-left"
                                    onClick={() => {
                                        setTimeout(() => {
                                            document.getElementsByTagName('BODY')[0].click();
                                        }, 0);
                                    }}
                                >
                                    <u>View Cart</u>
                                </Link>
                                <Link
                                    className="mt-3 pull-right"
                                    to="/checkout/customer-info"
                                    onClick={() => {
                                        setTimeout(() => {
                                            document.getElementsByTagName('BODY')[0].click();
                                        }, 0);
                                    }}
                                >
                                    <Button variant="primary">Checkout</Button>
                                </Link>
                            </>
                        ) : null}
                    </div>
                </>
            );
        } else {
            return (
                <div id="cart-dropdown-item">
                    <div className="container text-center py-7">
                        <img src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}icon-cart.svg`} width="80" alt="cart-icon" />
                        <p className="lead mt-5">Your shopping cart is empty.</p>
                    </div>
                </div>
            );
        }
    };

    const renderCart = () => {
        return (
            <>
                <div className="dropdown-header pb-0 d-flex justify-content-between">
                    <div>
                        <p>
                            <strong id="cart-dropdown-totalPrice">Total: </strong>
                            {currencyFormat(orderTotal)}
                        </p>
                    </div>
                    <div className="icon">
                        <img src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}icon-cart.svg`} alt="" width="22" height="19" />
                        <span className="ml-3 badge badge-number-dropdown badge-primary">{lineItemQty}</span>
                    </div>
                </div>
                <div className="dropdown-divider mt-3 mb-0"></div>
                <div id="cart-dropdown-wrapper">{renderCartBody()}</div>
            </>
        );
    };

    return (
        <Dropdown>
            <DropdownTrigger>
                <div className={`item d-flex align-items-center px-4 cursor-pointer drop-down`}>
                    <div className="dropdown-toggle d-flex justify-content-between">
                        <div className={`cart-icon mr-3 d-flex align-items-center`}>
                            <img src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}icon-cart.svg`} alt="Steve's Liquor Store Cart icon" />
                        </div>
                        <div className="details ml-2">
                            <h6 className="title" id="cart-total-price">
                                {currencyFormat(orderTotal)}
                            </h6>
                            <small>in your cart</small>
                        </div>
                    </div>
                </div>
            </DropdownTrigger>
            <DropdownContent>
                <div className="dropdown-menu show dropdown-menu-right cart-dropdown mt-3">{renderCart()}</div>
            </DropdownContent>
        </Dropdown>
    );
};

const mapStateToProps = (state, props) => {
    return {
        order: state.order.order.order,
        lineItems: state.order.order.lineItems,
        adjustments: state.order.order.adjustments
    };
};

export default connect(mapStateToProps, {})(CartDropDown);
