import { useDispatch } from 'react-redux';
import { saveToken } from 'toolbox/Token';
import api from 'apis/api';
import { LOGIN, SIGNUP, LOGOUT, MODAL_TOGGLE } from 'actions/types';

export default () => {
    const dispatch = useDispatch();

    const signIn = async ({ username, password, signinFrom = null, showError = true }) => {
        const response = await api.post('api/v1/user/login', { username, password, signinFrom });
        if (showError) dispatch({ type: LOGIN, payload: response.data });
        saveToken(response.data.auth);
        if (!response.data.error) {
            window.location.reload();
        }
        return response.data.error ? true : false;
    };

    const signUp = async (user) => {
        const response = await api.post('api/v1/user/signup', { user: user });
        dispatch({ type: SIGNUP, payload: response.data });
        saveToken(response.data.auth);

        if (response.data.user) {
            window.location.reload();
        }
    };

    const signOut = async () => {
        const response = await api.post('api/v1/user/logout', { act: 'logout' });
        dispatch({ type: LOGOUT, payload: response.data });
        saveToken(response.data.auth);

        window.location.reload();
    };

    const modalToggle = (source, status) => {
        dispatch({
            type: MODAL_TOGGLE,
            payload: {
                source,
                status
            }
        });
    };

    return { signIn, signUp, signOut, modalToggle };
};
