import { combineReducers } from 'redux';
import orderReducer from './orderReducer';
import userReducer from './userReducer';
import navReducer from './navReducer';
import alertReducer from './alertReducer';
import modalReducer from './modalReducer';
import siteReducer from './siteReducer';
import entryReducer from './entryReducer';
import storeReducer from './storeReducer';

export default combineReducers({
    nav: navReducer,
    user: userReducer,
    order: orderReducer,
    alert: alertReducer,
    modal: modalReducer,
    site: siteReducer,
    entry: entryReducer,
    store: storeReducer
});
