import React, { useState } from 'react';
import './_scrollToTop.scss';

const ScrollToTop = () => {
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.addEventListener('scroll', checkScrollTop);

    if (!showScroll) return null;
    return (
        <svg className="scrollTop" viewBox="0 0 512.000000 512.000000" onClick={() => scrollTop()}>
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={'var(--color-primary)'} stroke="none">
                <path
                    d="M2335 4789 c-518 -50 -1015 -292 -1385 -675 -861 -892 -836 -2303 56
                            -3164 336 -324 729 -521 1204 -602 180 -31 520 -31 700 0 503 86 922 306 1265
                            664 321 335 516 726 597 1198 18 103 22 165 22 350 0 185 -4 247 -22 350 -81
                            475 -278 868 -602 1204 -479 496 -1144 741 -1835 675z m370 -1488 c45 -22 185
                            -140 545 -456 267 -235 512 -451 545 -480 54 -46 62 -59 79 -118 64 -229 -67
                            -430 -290 -445 -53 -3 -85 0 -125 14 -30 10 -58 17 -62 16 -4 -1 -194 162
                            -423 363 l-415 365 -391 -352 c-260 -233 -409 -360 -442 -376 -75 -37 -182
                            -41 -260 -12 -162 61 -251 241 -200 406 10 32 18 64 19 70 0 15 1024 934 1092
                            981 102 70 216 79 328 24z"
                />
            </g>
        </svg>
    );
};

export default ScrollToTop;
