import React from 'react';
import ReactDom from 'react-dom';
import App from 'page/App';

import { Provider } from 'react-redux';
import {
    createStore,
    applyMiddleware
    // compose
} from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import { ToastProvider } from 'react-toast-notifications';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    applyMiddleware(reduxThunk)
    // composeEnhancers(applyMiddleware(reduxThunk))
);


ReactDom.render(
    <Provider store={store}>
        <ToastProvider /* placement="top-left" */>
            <App />
        </ToastProvider>
    </Provider>,
    document.querySelector('#root')
);
