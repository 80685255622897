import React, { useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import history from 'toolbox/history';
import MegaMenuVertical from './MegaMenuVertical';
import { useTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import 'react-awesome-slider/dist/styles.css';
import './_nav_slider.scss';
import './_nav_subcat_image.scss';
import './_dropdown.scss';

const MegaMenu = ({ prim, primTitle, navSpecial }) => {
    const theme = useTheme();
    const [autoPlay, setAutoPlay] = useState();

    const renderNavSlider = () => {
        // console.log(navSpecial);
        if (!navSpecial) {
            return null;
        }
        let AutoplaySlider = withAutoplay(AwesomeSlider);
        return (
            <>
                <h3 className="text-center text-dark">Looking for ideas?</h3>
                <div className="slider">
                    <AutoplaySlider play={autoPlay} cancelOnInteraction={false} interval={3000}>
                        {navSpecial.map((spec, index) => {
                            return (
                                <div
                                    data-src={spec.ImageUrl}
                                    key={index}
                                    onClick={(e) => {
                                        if (!e.target.classList.contains('s-info')) {
                                            history.push(`/product/${spec.Slug}`);
                                            setTimeout(() => {
                                                document.getElementsByTagName('BODY')[0].click();
                                            }, 0);
                                        }
                                    }}
                                >
                                    <div className="special-info s-info">
                                        <div className="slider-title s-info">{spec.Brand || ''}</div>
                                        <div className="slider-title s-info">{spec.VariantTitle}</div>
                                        <p className="price text-center s-info">${spec.SalePrice}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </AutoplaySlider>
                </div>
            </>
        );
    };

    const renderStatic = () => {
        const p = <p>{navSpecial.text}</p>;
        return (
            <div className="px-5">
                <img src={navSpecial.imageUrl} alt={navSpecial.text} width={202} height={253} />
                <div style={{ padding: '5px 0' }}>
                    <p style={{ fontSize: 15 }}>
                        {navSpecial.text.length > 109 ? <>{navSpecial.text.substring(0, 109) + '... '}</> : navSpecial.text + ' '}
                        <a href={navSpecial.link} target="_blank" rel="noopener noreferrer" style={{ fontSize: 12 }}>
                            Read more
                        </a>
                    </p>
                </div>
            </div>
        );
    };

    const RenderNavSpecial = () => {
        switch (theme.nav.navSpecialType) {
            case 'slider':
                return renderNavSlider();

            case 'static-image':
                return renderStatic();
            default:
                return renderNavSlider();
        }
    };

    const RenderDropDownMenu = () => {
        return <MegaMenuVertical prim={prim} />;
    };

    const megaMenu = (
        <Dropdown
            onShow={() => {
                setAutoPlay(true);
            }}
            onHide={() => {
                setAutoPlay(false);
            }}
        >
            <DropdownTrigger>
                <span className="custom-nav-bar-mega nav-link">
                    {primTitle}
                    <Icon style={{marginBottom:"-5px"}}>arrow_drop_down</Icon>
                </span>
            </DropdownTrigger>
            <DropdownContent>
                <nav className={`dropdown-menu show`}>
                    <div className="row bg-white h-100 py-4">
                        <div className="col-3 py-3 pr-0 nav-slider-col h-100">{RenderNavSpecial()}</div>
                        <div className="col-9 px-5 pt-3 pb-5 h-100">
                            {theme.nav.slogan ? <h3 className="title">{theme.nav.slogan}</h3> : null}
                            <div className="row nav-mega-link-row">{RenderDropDownMenu()}</div>
                        </div>
                    </div>
                </nav>
            </DropdownContent>
        </Dropdown>
    );

    return <li className="nav-item nav-mega">{megaMenu}</li>;
};

export default MegaMenu;
