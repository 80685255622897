import { INIT_WEB, SIGNUP, UPDATE_CART, UPDATE_ADJ, VALIDATE_SOH } from '../actions/types';
import _ from 'lodash';

export default (state = {}, action) => {
    switch (action.type) {
        case INIT_WEB:
            return initWeb(state, action);

        case SIGNUP:
            return signup(state, action);

        case UPDATE_CART:
            return updateCart(state, action);

        case UPDATE_ADJ:
            return updateAdj(state, action);

        case VALIDATE_SOH:
            return validateSOH(state, action);

        default:
            return state;
    }
};

const initWeb = (state, action) => {
    const response = action.payload;
    // const storeOptions = response.storeOptions;
    // const storesUnion = _.keyBy(_.union(storeOptions.VIC, storeOptions.QLD, storeOptions.TAS, storeOptions.NSW), 'locationId');
    const lineItemsKeyMap = _.keyBy(response.order.lineItems, 'VariantId');

    return {
        ...state,
        orderId: response.orderId,
        order: response.order,
        // store: storesUnion[response.locationId],
        // storeOptions: response.storeOptions,
        lineItemsKeyMap: lineItemsKeyMap
    };
};

const signup = (state, action) => {
    const response = action.payload;
    return {
        ...state,
        orderId: response.orderId
    };
};

const updateCart = (state, action) => {
    const response = action.payload;

    return {
        ...state,
        order: response.order,
        orderId: response.orderId
    };
};

const updateAdj = (state, action) => {
    const response = action.payload;

    return {
        ...state,
        order: response.order,
        orderId: response.orderId
    };
};

const validateSOH = (state, action) => {
    const response = action.payload;

    return {
        ...state,
        order: response.order,
        orderId: response.orderId
    };
};
