import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Drawer from 'components/Drawer';
import useAuth from 'hooks/useAuth';
import history from 'toolbox/history';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        height: 70
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.25)'
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        width: '100%',
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}));

export default ({ user, navMenu, mobileLogoUrl, entryStatus }) => {
    const classes = useStyles();
    const navIcons = {
        about: 'home_work',
        contact: 'phone',
        'terms-conditions': 'notes',
        'privacy-policy': 'security',
        specials: 'import_contacts',
        'mobile':'stay_primary_portrait',
        // 'infinity-rewards':'attach_money',
        'rewards':'attach_money',
    };
    const entries = entryStatus.map((entry) => {
        return parseInt(entry.enabled)
            ? { text: entry.title, icon: navIcons[entry.slug], closeOnClick: true, callback: () => history.push(`/${entry.slug}`) }
            : null;
    });

    const { signOut } = useAuth();
    const [openLeftMenu, setOpenLeftMenu] = useState(false);
    const [leftMenu, setLeftMenu] = useState();


    const handleClickPriCat = ({ opt, selected }) => {
        let menu = [];
        switch (opt) {
            case 'init':
                menu = [
                    {
                        header: {
                            icon: null,
                            text: 'Main Menu',
                            className: 'bg-secondary',
                            callback: () => null
                        },
                        lists: Object.keys(navMenu).map((priTitle) => {
                            return {
                                icon: 'reorder',
                                text: priTitle,
                                closeOnClick: false,
                                callback: () => handleClickPriCat({ opt: 'secondary', selected: { pri: priTitle, sec: null, sub: null } })
                            };
                        })
                    },
                    {
                        lists: entries
                    }
                ];
                break;

            case 'secondary':
                menu = [
                    {
                        header: {
                            icon: 'arrow_back_ios',
                            text: selected.pri,
                            className: 'bg-secondary',
                            callback: () => handleClickPriCat({ opt: 'init' })
                        },
                        lists: Object.keys(navMenu[selected.pri]).map((secTitle) => {
                            return {
                                icon: 'reorder',
                                text: secTitle,
                                closeOnClick: false,
                                callback: () => handleClickPriCat({ opt: 'sub', selected: { ...selected, sec: secTitle } })
                            };
                        })
                    },
                    {
                        lists: entries
                    }
                ];
                break;

            case 'sub':
                menu = [
                    {
                        header: {
                            icon: 'arrow_back_ios',
                            text: `${selected.pri} / ${selected.sec}`,
                            className: 'bg-secondary',
                            callback: () => handleClickPriCat({ opt: 'secondary', selected: { ...selected, pri: selected.pri } })
                        },
                        lists: [
                            {
                                icon: 'reorder',
                                text: 'All',
                                closeOnClick: true,
                                callback: () => history.push(`/shop/${navMenu[selected.pri][selected.sec].SecSlug}`)
                            }
                        ].concat(
                            navMenu[selected.pri][selected.sec].SubCat.map((subTitle, index) => {
                                return {
                                    icon: 'reorder',
                                    text: subTitle,
                                    closeOnClick: true,
                                    callback: () =>
                                        history.push(
                                            `/shop/${navMenu[selected.pri][selected.sec].SecSlug}/${
                                                navMenu[selected.pri][selected.sec].SubSlug[index]
                                            }`
                                        )
                                };
                            })
                        )
                    },
                    {
                        lists: entries
                    }
                ];
                break;
            default:
        }
        if (user) menu.push({ lists: [{ text: 'Log Out', icon: 'exit_to_app', callback: () => signOut() }] });

        setLeftMenu(menu);
    };

    useEffect(() => {
        handleClickPriCat({ opt: 'init' });
        //eslint-disable-next-line
    }, []);

    const handleOpenLeftMenu = (open) => {
        setOpenLeftMenu(open);
    };

    if (!leftMenu) return null;

    return (
        <div className={classes.grow}>
            <AppBar
                position="fixed"
                style={{
                    backgroundColor: 'var(--color-primary)',
                    color: 'var(--color-light)',
                    marginBottom: 20,
                    boxShadow: 'none',
                    height: 50
                }}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleOpenLeftMenu(!openLeftMenu)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    history.push('/shop?search=' + e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.grow} />
                </Toolbar>
            </AppBar>
            <Drawer isOpen={openLeftMenu} handleOpenCallback={handleOpenLeftMenu} anchor={'left'} menu={leftMenu} logo={mobileLogoUrl} />
        </div>
    );
};
