import React from 'react';
import history from 'toolbox/history';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error('Error Boundary Caught:', error, errorInfo);
    }
    render() {
        const { hasError } = this.state;
        if (hasError) {
            // if (error.name === 'ChunkLoadError') {
            history.push(this.props.redirect);
            // window.location.reload();
            // }
        }
        return this.props.children;
    }
}
