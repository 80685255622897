import { INIT_WEB, LOADING_STATUS, INIT_STATUS } from '../actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case INIT_WEB:
            return init(state, action);

        case LOADING_STATUS:
            return loadingStatus(state, action);

        case INIT_STATUS:
            return initStatus(state, action);

        default:
            return state;
    }
};

const init = (state, action) => {
    const payload = action.payload;
    return {
        ...state,
        siteSettings: payload.siteSettings
    };
};

const loadingStatus = (state, action) => {
    return {
        ...state,
        loading: action.payload
    };
};

const initStatus = (state, action) => {
    return {
        ...state,
        initializing: action.payload
    };
};
