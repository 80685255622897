import React, { useState } from 'react';
import { CircularProgress, Icon } from '@material-ui/core';
import useUpdateCart from 'hooks/useUpdateCart';

export default ({ purchasableId }) => {
    const [updateCart] = useUpdateCart();
    const [onDeleting, setOnDeleting] = useState(false);

    const handleDeleteLineItem = async () => {
        setOnDeleting(true);
        await updateCart({
            purchasableId,
            qty: 0
        });
        setOnDeleting(false);
    };

    return (
        <>
            <div style={onDeleting ? { display: 'none' } : null}>
                <Icon className={`remove-line-item cursor-pointer`} onClick={() => handleDeleteLineItem()}>
                    delete
                </Icon>
            </div>
            <div className={`remove-line-item cursor-pointer`} style={onDeleting ? null : { display: 'none' }}>
                <CircularProgress size={18} />
            </div>
        </>
    );
};
