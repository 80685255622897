export const INIT_WEB = 'INIT_WEB';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_ENTRY = 'GET_ENTRY';
export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_ADJ = 'UPDATE_ADJ';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const ALERT = 'ALERT';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const VALIDATE_SOH = 'VALIDATE_SOH';
export const MODAL_TOGGLE = 'MODAL_TOGGLE';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const LOADING_STATUS = 'LOADING_STATUS';
export const INIT_STATUS = 'INIT_STATUS';

export const FETCH_ENTRY = 'FETCH_ENTRY';

export const DISPLAY_TOKEN = true;
