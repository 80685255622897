export default () => {
    const onSocialSignin = async (callback, response) => {
        // console.log(response);
        const profile = response.additionalUserInfo.profile;
        let signinFrom;
        let username;

        switch (response.credential.signInMethod) {
            case 'google.com':
                signinFrom = 'google';
                username = `g-${profile.id}`;

                break;
            case 'facebook.com':
                signinFrom = 'facebook';
                username = `fb-${profile.id}`;
                break;

            default:
                signinFrom = null;
                username = '';
        }

        return await callback({ username, signinFrom, showError: false });
    };

    const onSocialSignup = async (callback, response, signupInfo) => {
        const profile = response.additionalUserInfo.profile;
        let username;
        let email;
        let firstName;
        let lastName;
        switch (response.credential.signInMethod) {
            case 'google.com':
                username = `g-${profile.id}`;
                email = profile.email;
                firstName = profile.given_name;
                lastName = profile.family_name;
                break;
            case 'facebook.com':
                username = `fb-${profile.id}`;
                email = profile.email;
                firstName = profile.first_name;
                lastName = profile.last_name;
                break;
            default:
                username = '';
                email = '';
                firstName = '';
                lastName = '';
        }

        const user = {
            username,
            email,
            password: null,
            firstName,
            lastName,
            dob: signupInfo.dob,
            promotionEmail: signupInfo.promotionEmail
        };
        await callback(user);
    };

    return { onSocialSignin, onSocialSignup };
};
