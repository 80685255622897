import React, { useState } from 'react';
import { isValidDate, getMoment } from 'toolbox/DateTimeHelper';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Checkbox from 'components/Checkbox';
import { BtnGoogle, BtnFacebook } from 'components/AuthBtn';
import useSocialRegister from 'hooks/useSocialRegister';
import AppContext from 'context/app-context';
import useAuth from 'hooks/useAuth';

const ModalSignup = ({ show, siteSettings, signupError }) => {
    const { signUp, modalToggle } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [dob, setDob] = useState('');
    const [promotionEmail, setPromotionEmail] = useState(true);
    const [termsConds, setTermsConds] = useState(true);
    // const [isInValidEmail, setIsInValidEmail] = useState(false);
    const [isInValidPassword, setIsInValidPassword] = useState(false);
    const [isInValidPasswordConfirm, setIsInValidPasswordConfirm] = useState(false);
    const [isInValidDob, setIsInValidDob] = useState(false);

    const [dobError, setDobError] = useState('');
    const [termsCondError, setTermsCondError] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const isMobile = React.useContext(AppContext).isMobile;

    const { onSocialSignup } = useSocialRegister();

    const actionSignup = async (e) => {
        e.preventDefault();

        let toSubmit = true;
        const isDobValid = isValidDate(dob);
        const now = getMoment();
        const mdob = getMoment(dob, 'DD/MM/YYYY');

        if (password.length < 6) {
            setIsInValidPassword(true);
            toSubmit = false;
        } else {
            setIsInValidPassword(false);
        }

        if (password !== passwordConfirm) {
            setIsInValidPasswordConfirm(true);
            toSubmit = false;
        } else {
            setIsInValidPasswordConfirm(false);
        }

        if (!isDobValid) {
            setIsInValidDob(true);
            setDobError('Please input as DD/MM/YYYY');
            toSubmit = false;
        } else if (now.diff(mdob, 'months') < 18 * 12) {
            setIsInValidDob(true);
            setDobError("Sorry. We can't register a user that is under 18 years old.");
            toSubmit = false;
        } else {
            setIsInValidDob(false);
        }

        if (!termsConds) {
            setTermsCondError('Please agree our terms and conditions');
            toSubmit = false;
        } else {
            setTermsCondError('');
        }

        if (toSubmit) {
            const user = {
                username: email,
                password: password,
                dob: dob,
                promotionEmail: promotionEmail
            };
            setIsRegistering(true);
            await signUp(user);
            setIsRegistering(false);
        }
    };

    const proceedValidation = () => {
        if (!termsConds) {
            setTermsCondError('Please agree our terms and conditions');
            return false;
        } else {
            setTermsCondError('');
            return true;
        }
    };

    const _onSocialSignup = async (response) => {
        setIsRegistering(true);
        const signupInfo = { dob, promotionEmail };
        const callback = signUp;
        await onSocialSignup(callback, response, signupInfo);
        setIsRegistering(false);
    };

    return (
        <Modal show={show} onHide={() => modalToggle('signup', false)} size="lg" centered className="modal-steves">
            <Modal.Header closeButton className="pt-7 d-flex justify-content-center">
                <Modal.Title>
                    <img className="theme-logo-fix mx-auto" src={siteSettings.logoUrl} alt="logo" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h1 className="text-center">SIGN UP</h1>
                <Form onSubmit={(e) => actionSignup(e)}>
                    <div className="row">
                        <div className="col-lg-9 col-sm-12 mx-auto">
                            <div className="row">
                                <div className="col-12 px-3">
                                    <Form.Group controlId={'email'} className="w-100 mb-0">
                                        <Form.Control
                                            type="email"
                                            placeholder="E-mail"
                                            isInvalid={signupError}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required={true}
                                        />
                                        <Form.Control.Feedback type="invalid">{signupError}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 px-3 mt-5">
                                    <Form.Group controlId={'password'} className="w-100 mb-0">
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            isInvalid={isInValidPassword}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {isInValidPassword ? 'New Password should contain at least 6 characters' : null}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 col-sm-12 px-3 mt-5">
                                    <Form.Group controlId={'password-confirm'} className="w-100 mb-0">
                                        <Form.Control
                                            type="password"
                                            placeholder="Confirm password"
                                            isInvalid={isInValidPasswordConfirm}
                                            value={passwordConfirm}
                                            onChange={(e) => setPasswordConfirm(e.target.value)}
                                            required={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {isInValidPasswordConfirm ? 'Passwords do not match' : null}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 px-3">
                                    <div className="form-group text-center">
                                        <label className="color-black" htmlFor="dob">
                                            Date Of birth
                                        </label>
                                        <Form.Group controlId={'dob'} className="w-100 mb-0">
                                            <Form.Control
                                                type="text"
                                                placeholder="DD/MM/YYYY"
                                                isInvalid={isInValidDob}
                                                value={dob}
                                                onChange={(e) => setDob(e.target.value)}
                                                required={true}
                                            />
                                            <Form.Control.Feedback type="invalid">{dobError}</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>

                            <div className="custom-control my-5">
                                <div className="form-group">
                                    <Checkbox
                                        text={`I agree to ${siteSettings.storeName} sending me marketing and promotional material via email`}
                                        checked={promotionEmail}
                                        onChange={() => setPromotionEmail(!promotionEmail)}
                                    />
                                </div>
                                <div className="form-group">
                                    <Checkbox
                                        text={
                                            <>
                                                I agree to the terms of use and{' '}
                                                <Link to="/privacy-policy" onClick={() => modalToggle('signup', false)}>
                                                    privacy policy
                                                </Link>
                                            </>
                                        }
                                        checked={termsConds}
                                        onChange={() => setTermsConds(!termsConds)}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer d-flex flex-column justify-content-center">
                                <Button type="submit" variant={'primary'} className="mx-auto" disabled={isRegistering}>
                                    CREATE ACCOUNT
                                </Button>

                                <div className="my-3 text-center">or Signup with</div>
                                <div className={`social-btn-wrapper ${isMobile ? 'social-btn-wrapper-mobile' : ''}`}>
                                    <BtnGoogle callbefore={proceedValidation} btnText="Google" callback={_onSocialSignup} />
                                    <BtnFacebook callbefore={proceedValidation} btnText="Facebook" callback={_onSocialSignup} />
                                </div>
                            </div>

                            <div className="col-12 text-center text-error">{termsCondError}</div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        show: state.modal.source === 'signup' ? state.modal.status : false,
        signupError: state.user.signupError,
        siteSettings: state.site.siteSettings
    };
};

export default connect(mapStateToProps, {})(ModalSignup);
