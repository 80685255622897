export const demoSiteSettings = {
    logoUrl: 'https://victoriapark.com.au/app/themes/victoria-park/assets/img/media/logo_small.png',
    contact: {
        email: 'golf.shop@victoriapark.com.au',
        phone: '+617 3252 0666',
        address: '309 Herston Rd',
        state: 'QLD',
        city: 'Herston',
        zip: 4006,
        googleLink:
            'https://www.google.com/maps/place/Victoria+Park+Golf+Complex/@-27.4509012,153.0182269,17z/data=!3m1!4b1!4m5!3m4!1s0x6b9159fb5a4f6555:0xf02a35bd720a540!8m2!3d-27.4509012!4d153.0204156?hl=en-GB'
    },
    social: {
        facebook: 'https://www.facebook.com/VicParkBrisbane'
    }
};

export const demoNavSpecial = [
    {
        imageUrl:
            'https://scontent-dfw5-2.cdninstagram.com/v/t51.29350-15/127166503_188184846183664_4135705507501178801_n.jpg?_nc_cat=109&ccb=2&_nc_sid=8ae9d6&_nc_ohc=g0RJfQ3bZKYAX9SLTyS&_nc_ht=scontent-dfw5-2.cdninstagram.com&oh=da055f245b0ca1e05b2523c35ea155df&oe=5FE37955',
        text: 'Planning your event for 2021? Lock it in before December 21st and save! Enquire today by visiting our website.',
        link: 'https://www.instagram.com/p/CH_kJVHg3Cr/'
    },
    {
        imageUrl:
            'https://scontent-dfw5-1.cdninstagram.com/v/t51.29350-15/127090559_188966422849645_6049553410291724242_n.jpg?_nc_cat=110&ccb=2&_nc_sid=8ae9d6&_nc_ohc=Q2V_qYFPbjwAX9aBjYI&_nc_ht=scontent-dfw5-1.cdninstagram.com&oh=495f0c5b531a005064e4d4d59306ae99&oe=5FE3FECE',
        text: "Great news, we're now open at the Bistro every evening! Starting from Monday 30th of November 👏🍻",
        link: 'https://www.instagram.com/p/CH_kJVHg3Cr/'
    },
    {
        imageUrl:
            'https://scontent-dfw5-2.cdninstagram.com/v/t51.29350-15/127166503_188184846183664_4135705507501178801_n.jpg?_nc_cat=109&ccb=2&_nc_sid=8ae9d6&_nc_ohc=g0RJfQ3bZKYAX9SLTyS&_nc_ht=scontent-dfw5-2.cdninstagram.com&oh=da055f245b0ca1e05b2523c35ea155df&oe=5FE37955',
        text: 'Planning your event for 2021? Lock it in before December 21st and save! Enquire today by visiting our website.',
        link: 'https://www.instagram.com/p/CH_kJVHg3Cr/'
    },
    {
        imageUrl:
            'https://scontent-dfw5-1.cdninstagram.com/v/t51.29350-15/127090559_188966422849645_6049553410291724242_n.jpg?_nc_cat=110&ccb=2&_nc_sid=8ae9d6&_nc_ohc=Q2V_qYFPbjwAX9aBjYI&_nc_ht=scontent-dfw5-1.cdninstagram.com&oh=495f0c5b531a005064e4d4d59306ae99&oe=5FE3FECE',
        text: "Great news, we're now open at the Bistro every evening! Starting from Monday 30th of November 👏🍻",
        link: 'https://www.instagram.com/p/CH_kJVHg3Cr/'
    },
    {
        imageUrl:
            'https://scontent-dfw5-2.cdninstagram.com/v/t51.29350-15/127166503_188184846183664_4135705507501178801_n.jpg?_nc_cat=109&ccb=2&_nc_sid=8ae9d6&_nc_ohc=g0RJfQ3bZKYAX9SLTyS&_nc_ht=scontent-dfw5-2.cdninstagram.com&oh=da055f245b0ca1e05b2523c35ea155df&oe=5FE37955',
        text: 'Planning your event for 2021? Lock it in before December 21st and save! Enquire today by visiting our website.',
        link: 'https://www.instagram.com/p/CH_kJVHg3Cr/'
    },
    {
        imageUrl:
            'https://scontent-dfw5-1.cdninstagram.com/v/t51.29350-15/127090559_188966422849645_6049553410291724242_n.jpg?_nc_cat=110&ccb=2&_nc_sid=8ae9d6&_nc_ohc=Q2V_qYFPbjwAX9aBjYI&_nc_ht=scontent-dfw5-1.cdninstagram.com&oh=495f0c5b531a005064e4d4d59306ae99&oe=5FE3FECE',
        text: "Great news, we're now open at the Bistro every evening! Starting from Monday 30th of November 👏🍻",
        link: 'https://www.instagram.com/p/CH_kJVHg3Cr/'
    }
];
export const bannerUrls = [
    {
        imageUrl: 'https://victoriapark.com.au/app/themes/victoria-park/assets/img/default_header.jpg',
        imageName: 'demo-banner',
        redirectUrl: '#'
    }
];

export const demoNavMenu = {
    CLUBS: {
        Drivers: {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        Fairways: {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        Hybrids: {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },

        Irons: {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },

        Wedges: {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },

        Putters: {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },

        'Packages & Iron Sets': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        'New Release': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        }
    },
    'BALLS & GLOVES': {
        'New Balls': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        'Used Balls': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        "Men's Gloves": {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },

        "Women's Gloves": {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        }
    },

    CLOTHING: {
        'Presidents Cup': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        'Mens Shoes': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        'Womens Shoes': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },

        'Junior Shoes ': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        }
    },
    Buggies: {
        'Cart Bags': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        'Stand Bags': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        'Staff Bags': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        'Travel/Range Bags ': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        }
    },
    Accessories: {
        'Golf Prevention Recovery Centre': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        Playing: {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },
        'Practice Equipment': {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        },

        Eyewear: {
            SecSlug: '#',
            SubCat: ['#'],
            SubSlug: ['#']
        }
    }
};

export const demoNavSpecials = [
    {
        Image: 4826,
        ImageUrl: 'https://victoriapark.com.au/app/uploads/vic_giftcard-1-300x300.png',
        InventoryCode: 99874,
        LocalImage: '',
        SalePrice: 15.99,
        Slug: '#',
        VariantTitle: '$50 GIFT CARD'
    }
];

export const demoProducts = [
    {
        Brand: '',
        DealName: '15% Off For 3 Purchases',
        Discounts: [],
        Image: 4845,
        ImageUrl: 'https://victoriapark.com.au/app/uploads/vic_giftcard-1-300x300.png',
        Images: 4845,
        InventoryCode: 99948,
        LocalImage: '',
        LocalImages: '',
        MM_RuleName: ' % DISCOUNT',
        MaxVariantQty: 8,
        ProductId: 211595,
        ProductTitle: '$50 GIFT CARD',
        SalesInfo: {
            HasSale: 0
        },
        Url: '#',
        Variants: [
            {
                BestSinglePrice: 15.99,
                DealName: '15% Off For 3 Purchases',
                DiscountMethod: '',
                DiscountName: '',
                DiscountPara: '',
                ImageUrl: 'https://victoriapark.com.au/app/uploads/vic_giftcard-1-300x300.png',
                MM_RuleName: '15% Off For 3 Purchases',
                MinPrice: 15.99,
                Price: 15.99,
                PurchaseQty: '',
                Qty: 4,
                SKU: 1171,
                SaleDateDiff: '',
                SaleDateTo: '',
                SalePrice: 15.99,
                VariantId: 202082,
                VariantLabel: 'Each',
                VariantSize: 1
            }
        ]
    },
    {
        Brand: '',
        DealName: '$10 Off For Any 2 Mens Glove',
        Discounts: [],
        Image: 4845,
        ImageUrl:
            'https://www.drummondgolf.com.au/pub/media/catalog/product/cache/43655713701bd3154e44c030a0d925b7/z/o/zoom_tour_white_silver_blue.png',
        Images: 4845,
        InventoryCode: 99948,
        LocalImage: '',
        LocalImages: '',
        MM_RuleName: ' % DISCOUNT',
        MaxVariantQty: 8,
        ProductId: 211595,
        ProductTitle: 'Zoom Tour Mens Glove White/Silver/Blue',
        SalesInfo: {
            HasSale: 0
        },
        Url: '#',
        Variants: [
            {
                BestSinglePrice: 15.99,
                DealName: '15% Off For 3 Purchases',
                DiscountMethod: '',
                DiscountName: '',
                DiscountPara: '',
                ImageUrl:
                    'https://www.drummondgolf.com.au/pub/media/catalog/product/cache/43655713701bd3154e44c030a0d925b7/z/o/zoom_tour_white_silver_blue.png',
                MM_RuleName: '15% Off For 3 Purchases',
                MinPrice: 32.95,
                Price: 32.95,
                PurchaseQty: '',
                Qty: 4,
                SKU: 1171,
                SaleDateDiff: '',
                SaleDateTo: '',
                SalePrice: 32.95,
                VariantId: 202082,
                VariantLabel: 'Each',
                VariantSize: 1
            }
        ]
    },
    {
        Brand: '',
        DealName: '',
        Discounts: [],
        Image: 4845,
        ImageUrl: 'https://victoriapark.com.au/app/uploads/VictoriaPark-Membership-ShopImg-600x497.jpg',
        Images: 4845,
        InventoryCode: 99948,
        LocalImage: '',
        LocalImages: '',
        MM_RuleName: '',
        MaxVariantQty: 8,
        ProductId: 211595,
        ProductTitle: 'VICTORIA PARK MEMBERSHIP',
        SalesInfo: {
            HasSale: 0
        },
        Url: '#',
        Variants: [
            {
                BestSinglePrice: 60.0,
                DealName: '',
                DiscountMethod: '',
                DiscountName: '',
                DiscountPara: '',
                ImageUrl: 'https://victoriapark.com.au/app/uploads/VictoriaPark-Membership-ShopImg-600x497.jpg',
                MM_RuleName: '',
                MinPrice: 60.0,
                Price: 60.0,
                PurchaseQty: '',
                Qty: 4,
                SKU: 1171,
                SaleDateDiff: '',
                SaleDateTo: '',
                SalePrice: 60.0,
                VariantId: 202082,
                VariantLabel: 'Each',
                VariantSize: 1
            }
        ]
    },
    {
        Brand: '',
        DealName: '',
        Discounts: [],
        Image: 4845,
        ImageUrl: 'https://www.drummondgolf.com.au/pub/media/catalog/product/cache/43655713701bd3154e44c030a0d925b7/v/i/vivid_white.jpg',
        Images: 4845,
        InventoryCode: 99948,
        LocalImage: '',
        LocalImages: '',
        MM_RuleName: '',
        MaxVariantQty: 8,
        ProductId: 211595,
        ProductTitle: 'Volvik Vivid Golf Balls - White',
        SalesInfo: {
            HasSale: 0
        },
        Url: '#',
        Variants: [
            {
                BestSinglePrice: 49.95,
                DealName: '',
                DiscountMethod: '',
                DiscountName: '',
                DiscountPara: '',
                ImageUrl: 'https://www.drummondgolf.com.au/pub/media/catalog/product/cache/43655713701bd3154e44c030a0d925b7/v/i/vivid_white.jpg',
                MM_RuleName: '',
                MinPrice: 49.95,
                Price: 49.95,
                PurchaseQty: '',
                Qty: 4,
                SKU: 1171,
                SaleDateDiff: '',
                SaleDateTo: '',
                SalePrice: 49.95,
                VariantId: 202082,
                VariantLabel: 'Each',
                VariantSize: 1
            }
        ]
    },
    {
        Brand: '',
        DealName: '',
        Discounts: [],
        Image: 4845,
        ImageUrl: 'https://www.drummondgolf.com.au/pub/media/catalog/product/cache/43655713701bd3154e44c030a0d925b7/s/i/silver-hybrid.jpg',
        Images: 4845,
        InventoryCode: 99948,
        LocalImage: '',
        LocalImages: '',
        MM_RuleName: '',
        MaxVariantQty: 8,
        ProductId: 211595,
        ProductTitle: 'Powerbilt Junior Hybrid - Age 9-12',
        SalesInfo: {
            HasSale: 0
        },
        Url: '#',
        Variants: [
            {
                BestSinglePrice: 49.95,
                DealName: '',
                DiscountMethod: '',
                DiscountName: '',
                DiscountPara: '',
                ImageUrl: 'https://www.drummondgolf.com.au/pub/media/catalog/product/cache/43655713701bd3154e44c030a0d925b7/s/i/silver-hybrid.jpg',
                MM_RuleName: '',
                MinPrice: 49.95,
                Price: 49.95,
                PurchaseQty: '',
                Qty: 4,
                SKU: 1171,
                SaleDateDiff: '',
                SaleDateTo: '',
                SalePrice: 49.95,
                VariantId: 202082,
                VariantLabel: 'Each',
                VariantSize: 1
            }
        ]
    }
];
