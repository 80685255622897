import React from 'react';
import SignInStatus from './SignInStatus';
import MegaMenu from './MegaMenu';
import CartDropDown from './cart/CartDropDown';
import { Link } from 'react-router-dom';
import Search from './Search';
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import TopBar from 'components/header/TopBar';
import NavButton from 'components/header/NavButton';
import { demoNavMenu, demoNavSpecial } from 'demo/data';
import './_headerDesktop.scss';

const HeaderDesktop = ({ navMenu, navSpecials, logoUrl, ilgLogoUrl, store, user }) => {
    const theme = useTheme();
    navMenu = theme.demo ? demoNavMenu : navMenu;
    // logoUrl = theme.demo ? demoSiteSettings.logoUrl;

    const renderMegaMenu = () => {
        return Object.keys(navMenu).map((primTitle, index) => {
            const navSpecial = theme.demo ? demoNavSpecial[index] : navSpecials[primTitle];
            return <MegaMenu key={index} prim={navMenu[primTitle]} primTitle={primTitle} navSpecial={navSpecial} />;
        });
    };

    const renderFirstGrid = () => {
        switch (theme.firstGrid) {
            case 'logo':
                return (
                    <div className="item d-flex justify-content-around align-items-center px-4 cursor-pointer ilg-logo" style={{ width: 315 }}>
                        <img src={ilgLogoUrl} alt="logo" />
                    </div>
                );

            default:
                return null;
        }
    };

    const renderSecondGrid = () => {
        switch (theme.secondGrid) {
            case 'store':
                return (
                    <div className="item d-flex align-items-center px-4 cursor-pointer">
                        <a href={store.googleLink} target="_blank" rel="noopener noreferrer">
                            <div className="icon d-flex mr-3">
                                <img src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}icon-location.svg`} alt="Store Location Finder icon" />
                            </div>
                        </a>
                        <a href={store.googleLink} target="_blank" rel="noopener noreferrer">
                            <div className="details">
                                <h6 className="title">{store.name}</h6>
                            </div>
                        </a>
                    </div>
                );

            case 'contact':
                return (
                    <div
                        className="item d-flex justify-content-end align-items-center px-4 cursor-pointer no-vertical-line"
                        style={{ minWidth: 550 }}
                    >
                        <NavButton link="/about" icon="home" text="About Us" />
                        <NavButton link="/contact" icon="phone" text="Contact Us" />
                        {theme.showCatalogue ? <NavButton link="/specials" icon="import_contacts" text="Catalogues" /> : null}
                    </div>
                );

            default:
                return null;
        }
    };



    return (
        <section className={`header pt-${theme.topBar.display ? '0' : '4'} pb-0 mb-4 nav-fix-top`}>
            {theme.topBar.display ? <TopBar isSimpleTopBar={theme.topBar.simple} /> : null}

            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-3 d-flex align-items-center pr-0 logo-wrapper">
                        <Link className="navbar-brand mr-xl-2 mr-lg-0" to="/">
                            <img className="theme-logo" src={logoUrl} alt="Logo" />
                        </Link>
                    </div>
                    <div className="col-lg-10 col-9">
                        <div className="main-menu-top d-flex pb-2 py-3">
                            {renderFirstGrid()}
                            {renderSecondGrid()}

                            <div className="item d-flex align-items-center px-4">
                                <SignInStatus user={user} />
                            </div>
                            <CartDropDown />
                        </div>
                        <nav className="nav main-menu d-flex align-items-center">
                            <ul className="nav nav-navbar text-center">{renderMegaMenu()}</ul>
                            <div className="input-group search">
                                <Search />
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default connect(null, {})(HeaderDesktop);
