import React from 'react';
import { useToasts } from 'react-toast-notifications';

export default () => {
    const { addToast } = useToasts();

    const toastMessage = (message, dismissTimout = null) => {
        if (!message) return;
        if (message.type === 'hidden') return;
        const appearance = message.type;
        const autoDismiss = appearance === 'error' ? true : true;
        let autoDismissTimeout;
        switch (appearance) {
            case 'error':
                autoDismissTimeout = dismissTimout ?? 10000;
                break;
            case 'success':
                autoDismissTimeout = dismissTimout ?? 5000;
                break;
            case 'info':
                autoDismissTimeout = dismissTimout ?? 5000;
                break;
            default:
                autoDismissTimeout = dismissTimout ?? 5000;
                break;
        }
        const key = Object.keys(message.message)[0];
        message = (
            <>
                <b>{key}</b>: {message.message[key]}
            </>
        );
        addToast(message, {
            appearance,
            autoDismiss,
            autoDismissTimeout
        });
    };

    return [toastMessage];
};
