import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { BtnGoogle, BtnFacebook } from 'components/AuthBtn';
import useSocialRegister from 'hooks/useSocialRegister';
import useAuth from 'hooks/useAuth';

const ModalLogin = ({ isMobile, show, siteSettings, loginError, signupError }) => {
    const { signIn, signUp, modalToggle } = useAuth();
    const { onSocialSignin, onSocialSignup } = useSocialRegister();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggingIn, setIsLogging] = useState(false);
    const [emailClass, setEmailClass] = useState('');
    const [passwordClass, setPasswordClass] = useState('');

    const handleLogin = async () => {
        setEmailClass(username ? '' : 'is-invalid');
        setPasswordClass(password ? '' : 'is-invalid');

        if (username && password) {
            setIsLogging(true);
            const hasError = await signIn({ username, password });
            setIsLogging(!hasError);
        }
    };

    const _onSocialSignin = async (response) => {
        let callback = signIn;

        setIsLogging(true);
        const hasError = await onSocialSignin(callback, response);
        if (hasError) {
            callback = signUp;
            const userInfo = {
                dob: '',
                promotionEmail: true
            };
            onSocialSignup(callback, response, userInfo);
        }
        setIsLogging(!hasError);
    };

    return (
        <Modal
            show={show}
            onHide={() => modalToggle('login', false)}
            size="lg"
            centered
            className="modal-steves"
        >
            <Modal.Header closeButton className="pt-7 d-flex justify-content-center">
                <Modal.Title>
                    <img className="theme-logo-fix mx-auto" src={siteSettings.logoUrl} alt="logo" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h1 className="text-center mb-5 ">Login</h1>
                <div className="row">
                    <div className="col-lg-9 col-sm-12 mx-auto">
                        <div className="text-error">{loginError}</div>
                        <div className="text-error">{signupError}</div>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    className={`${emailClass} form-control`}
                                    type={'input'}
                                    placeholder={'E-mail'}
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required={true}
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-12">
                                <input
                                    className={`${passwordClass} form-control`}
                                    type={'password'}
                                    placeholder={'Password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleLogin();
                                        }
                                    }}
                                    required={true}
                                />
                            </div>
                        </div>

                        <div className="modal-footer d-flex flex-column justify-content-center">
                            <button
                                type="submit"
                                className="btn btn-primary mx-auto mt-5"
                                onClick={() => handleLogin()}
                                disabled={isLoggingIn}
                            >
                                {isLoggingIn ? 'Logging in...' : 'Log In'}
                            </button>

                            <div className="my-3 text-center">or Login with</div>
                            <div
                                className={`social-btn-wrapper ${
                                    isMobile ? 'social-btn-wrapper-mobile' : ''
                                }`}
                            >
                                <BtnGoogle
                                    callbefore={() => {
                                        return true;
                                    }}
                                    btnText="Google"
                                    callback={_onSocialSignin}
                                />
                                <BtnFacebook
                                    callbefore={() => {
                                        return true;
                                    }}
                                    btnText="Facebook"
                                    callback={_onSocialSignin}
                                />
                            </div>

                            <p>
                                Forgot your password?{' '}
                                <Link
                                    to="/account/reset-password"
                                    onClick={() => modalToggle('login', false)}
                                >
                                    Click here
                                </Link>
                            </p>
                            <p>
                                Don't have an account?{' '}
                                <Link to="#" onClick={() => modalToggle('signup', true)}>
                                    Register
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        show: state.modal.source === 'login' ? state.modal.status : false,
        loginError: state.user.loginError,
        signupError: state.user.signupError,
        siteSettings: state.site.siteSettings
    };
};

export default connect(mapStateToProps, {})(ModalLogin);
