export function readBase64Param(string) {
    return JSON.parse(atob(string));
}

export function mergeQuery(obj1, obj2) {
    Object.keys(obj2).forEach((key) => {
        obj1[key] = obj2[key];
    });

    return obj1;
}

export function encodeBase64(string) {
    return btoa(JSON.stringify(string));
}

export function getPathUrl() {
    return window.location.pathname + window.location.search;
}
