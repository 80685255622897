import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import HeaderMobile from './HeaderMobile';
import Modals from './modal/Modals';
import HeaderDesktop from './HeaderDesktop';

const Header = ({ isMobile, siteSettings, navMenu, navSpecials, user, store, storeOptions, entryStatus }) => {
    if (!siteSettings) {
        return null;
    }

    if (isMobile) {
        return (
            <>
                <div style={{ moinHeight: 50 }}></div>
                <HeaderMobile navMenu={navMenu} user={user} mobileLogoUrl={siteSettings.mobileLogoUrl} entryStatus={entryStatus} />
                <Modals isMobile={isMobile} />
            </>
        );
    }

    return (
        <>
            <div className="nav-fix-top-desktop-placeholder mb-5"></div>
            <HeaderDesktop
                navMenu={navMenu}
                navSpecials={navSpecials}
                user={user}
                store={store}
                storeOptions={storeOptions}
                logoUrl={siteSettings.logoUrl}
                ilgLogoUrl={siteSettings.ilgLogoUrl}
            />
            <Modals />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        orderId: state.order.orderId,
        store: state.store.store,
        storeOptions: state.store.storeOptions,

        navMenu: state.nav.navMenu,
        navSpecials: state.nav.navSpecials,
        siteSettings: state.site.siteSettings,
        entryStatus: state.nav.entryStatus
    };
};

export default connect(mapStateToProps, {})(withRouter(Header));
