import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import history from 'toolbox/history';
import { connect } from 'react-redux';
import { currencyFormat } from 'toolbox/CurrencyHelper';

const useStyles = makeStyles({
    root: {
        width: '100%',
        // position: 'absolute',
        position: 'fixed',
        bottom: 0,
        zIndex: 2000
    }
});

const BottomNav = ({ isMobile, orderTotal, shopIndexPathUrl, match }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState();

    const handleBottomNav = (path) => {
        setValue(path);
        history.push(path);
    };

    if (!isMobile) return null;

    return (
        <div className={classes.root}>
            <BottomNavigation
                value={value}
                onChange={(event, path) => {
                    handleBottomNav(path);
                }}
                showLabels
            >
                <BottomNavigationAction label="Home" value={'/'} icon={<HomeIcon />} />
                <BottomNavigationAction label="Shopping" value={shopIndexPathUrl} icon={<FormatListBulletedIcon />} />
                <BottomNavigationAction label="Account" value={'/account'} icon={<AccountCircleIcon />} />
                <BottomNavigationAction label={currencyFormat(orderTotal)} value={'/cart'} icon={<ShoppingCartIcon />} />
            </BottomNavigation>
        </div>
    );
};

const MSTP = (state) => {
    try {
        return {
            orderTotal: state.order.order.order.total,
            shopIndexPathUrl: state.entry.shopIndexPathUrl ?? '/shop'
        };
    } catch (e) {
        return {
            orderTotal: 0,
            shopIndexPathUrl: state.entry.shopIndexPathUrl ?? '/shop'
        };
    }
};

export default connect(MSTP)(BottomNav);
