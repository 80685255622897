import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { addToBookMark } from 'toolbox/BroswerHelper';
import theme from 'theme';
import AppContext from 'context/app-context';
import Icon from '@material-ui/core/Icon';
import { detect } from 'toolbox/DeviceDetectHelper';

import './_popUp.scss';

const Popup = ({ siteSettings, open = false, callbackOpenPopup, dismissSeconds = 8 }) => {
    const isMobile = React.useContext(AppContext).isMobile;

    useEffect(() => {
        if (siteSettings) {
            localStorage.setItem('showPopUp', 'false');
            if (dismissSeconds > 0) {
                setTimeout(() => callbackOpenPopup(false), dismissSeconds * 1000);
            }
        }
    }, [siteSettings, open, callbackOpenPopup, dismissSeconds]);

    // useEffect(() => {
    //     return () => {
    //         if (siteSettings) {
    //             localStorage.setItem('showPopUp', 'true');
    //         }
    //     };
    // }, [siteSettings]);

    // useEffect(() => {
    //     if (isMobile) {
    //         window.addEventListener('blur', () => {
    //             localStorage.setItem('showPopUp', 'true');
    //             setOpen(true);
    //         });
    //     }
    // });

    const handleClose = () => {
        callbackOpenPopup(false);
    };

    if (!siteSettings) return null;

    if (!open || !theme.popup || localStorage.getItem('showPopUp') === 'false') return null;

    if (!isMobile) {
        return (
            <div className="desktop-popup-wrapper">
                <div className="btn-close">
                    <Icon className="cursor-pointer" onClick={() => handleClose()}>
                        clear
                    </Icon>
                </div>
                <div className="img-wrapper">
                    <img src={siteSettings.logoUrl} alt="logo" />
                </div>
                <div className="text1">
                    The team at Liquor Warehouse are proud to showcase our new website, where you can buy all of your favourites online directly
                    through us! Make sure to bookmark this page for safekeeping, and enjoy browsing through our virtual store.
                </div>
                <div className="text2">{siteSettings.SITE_NAME}</div>
                <Button variant="primary" className="btn-bookmark" onClick={() => addToBookMark()}>
                    Add bookmark
                </Button>
            </div>
        );
    } else if (detect() === 'iOS') {
        return (
            <div className="ios-popup-wrapper">
                <div className="btn-close">
                    <Icon className="cursor-pointer" onClick={() => handleClose()}>
                        clear
                    </Icon>
                </div>
                <div className="img-wrapper">
                    <img src={siteSettings.favicon} alt="logo" />
                </div>
                <p className="mb-1">
                    To add this page to the
                    <br /> home screen, tap & select
                    <br />
                    <b>Add to Home Screen</b>
                </p>
            </div>
        );
    } else if (detect() === 'Android') {
        return (
            <div className="android-popup-wrapper">
                <div className="btn-close">
                    <Icon className="cursor-pointer" onClick={() => handleClose()}>
                        clear
                    </Icon>
                </div>
                <div className="img-wrapper">
                    <img src={siteSettings.favicon} alt="logo" />
                </div>
                <p className="mb-1">
                    To add this page to the home
                    <br /> screen, open the browser <br /> option menu & tap <br />
                    <b>Add to Home Screen</b>
                </p>
                <hr />
                <p className="mb-1 small">
                    The menu can be accessed by pressing the menu <br />
                    hardware button if your device has one, or by <br />
                    tapping the top right menu icon <Icon style={{ margingBottom: '-5px', fontSize: '1rem' }}>more_vert</Icon>
                </p>
            </div>
        );
    }
};

const mampStateToProps = (state) => {
    return {
        siteSettings: state.site.siteSettings
    };
};

export default connect(mampStateToProps, {})(Popup);
