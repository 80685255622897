import React from 'react';
import ModalLogin from './ModalLogin';
import ModalSignup from './ModalSignup';

export default () => {
    return (
        <>
            <ModalLogin />
            <ModalSignup />
        </>
    );
};
