import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles({
    list: {
        width: 250,
        paddingBottom: 70
    },
    fullList: {
        width: 'auto'
    }
});

const Drawer = ({ isOpen, handleOpenCallback, anchor, menu, logo }) => {
    const classes = useStyles();
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const handleClickEvent = (callback, closeOnClick) => {
        if (closeOnClick) handleOpenCallback(false);
        if (!callback) return;
        callback();
    };

    const RenderHeader = ({ header }) => {
        if (!header) return null;
        return (
            <List className={header.className}>
                <ListItem onClick={header.callback}>
                    <ListItemIcon>
                        <Icon className="color-white">{header.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={header.text} primaryTypographyProps={{ style: { color: 'white', fontWeight: 'bold' } }} />
                </ListItem>
            </List>
        );
    };

    const RenderList = (list) => {
        if (!list) return null;
        return (
            <ListItem button onClick={() => handleClickEvent(list.callback, list.closeOnClick)}>
                {list.icon ? (
                    <ListItemIcon>
                        <Icon>{list.icon}</Icon>
                    </ListItemIcon>
                ) : null}
                <ListItemText primary={list.text} />
            </ListItem>
        );
    };

    const RenderMenuSection = (section, index) => {
        return (
            <>
                {RenderHeader(section)}
                <List>
                    {section.lists.map((list, index) => {
                        return RenderList(list);
                    })}
                </List>
                {index === menu.length - 1 ? null : <Divider style={{ marginTop: 20 }} />}
            </>
        );
    };

    const RenderMenu = () => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom'
            })}
            role="presentation"
        >
            {menu.map((section, index) => RenderMenuSection(section, index))}
        </div>
    );

    const renderLogo = () => {
        if (!logo) return null;
        return (
            <div className="px-7 py-5 d-flex justify-content-center" style={{ minHeight: 92 }}>
                <img className="m-logo" src={logo} alt="logo" />
            </div>
        );
    };

    return (
        <div>
            <React.Fragment key={anchor}>
                <SwipeableDrawer disableBackdropTransition={!iOS} anchor={anchor} open={isOpen} onClose={(e) => handleOpenCallback(false)}>
                    {renderLogo()}
                    {RenderMenu()}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
};

export default Drawer;
