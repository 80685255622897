import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { currencyFormat } from 'toolbox/CurrencyHelper';
import DeleteIcon from './DeleteIcon';

const CartLineItem = ({ item, adj }) => {
    const [displayIcon, setDisplayIocn] = useState(false);

    return (
        <>
            <div
                className="row row-line-items py-3"
                onMouseEnter={() => {
                    setDisplayIocn(true);
                }}
                onMouseLeave={() => {
                    setDisplayIocn(false);
                }}
            >
                <div className="col-4 row-line-items-image">
                    <Link className="dropdown-item py-0 px-0 cursor-pointer" to={`/product/${item.slug}`}>
                        <div className="cart-dropdown-img">
                            <img src={item.imageUrl} className="lazy" alt={item.imageUrl} />
                        </div>
                    </Link>
                </div>
                <div className="col-8 row-line-items-content">
                    {displayIcon && <DeleteIcon purchasableId={item.purchasableId} />}

                    <Link
                        className="dropdown-item py-0 px-0 cursor-pointer"
                        to={`/product/${item.slug}`}
                        onClick={() => {
                            setTimeout(() => {
                                document.getElementsByTagName('BODY')[0].click();
                            }, 0);
                        }}
                    >
                        <p className="cart-prod-title w-100 my-1 text-wrap cart-dropdown-ptitle">{item.description}</p>
                    </Link>
                    <p className="cart-prod-title w-100">
                        <span className="cart-pop-qty correct-qty">{item.qty}</span> x{' '}
                        <span className="cart-pop-price">{currencyFormat(item.salePrice * 1)}</span>
                        <span className="discount-text">
                            <br /> {item.variantLabel}
                        </span>
                        {adj ? (
                            <span className="discount-text">
                                <br />
                                Multi Buy Discount {currencyFormat(adj.amount)}
                            </span>
                        ) : null}
                    </p>
                </div>
            </div>
            <div className="dropdown-divider my-0"></div>
        </>
    );
};

export default CartLineItem;
