import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { useTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import './_nav_subcat_image.scss';

const MegaMenuVertical = (props) => {
    const theme = useTheme();
    const prim = props.prim;
    const columnMinItemCnt = 6;
    const [secChild, setSecChild] = useState();
    const [activedSec, setActivedSec] = useState();

    useEffect(() => {
        activeSec(Object.keys(prim)[0]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const activeSec = (secTitle) => {
        setSecChild(prim[secTitle]);
        setActivedSec(secTitle);
    };

    if (!secChild) {
        return null;
    }

    const renderSubCategory = () => {
        switch (theme.nav.subCatType) {
            case 'text':
                return (
                    <>
                        <div className="pl-3 w-50">
                            {secChild.SubCat.map((sub, index) => {
                                if (
                                    index >= 0 &&
                                    (index < Math.ceil(secChild.SubCat.length / 2) ||
                                        secChild.SubCat.length <= columnMinItemCnt ||
                                        index === columnMinItemCnt - 1)
                                ) {
                                    return (
                                        <div className="py-1" key={index}>
                                            <Link
                                                className="nav-link"
                                                to={theme.demo ? '/' : `/shop/${secChild.SecSlug}/${secChild.SubSlug[index]}`}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        document.getElementsByTagName('BODY')[0].click();
                                                    }, 0);
                                                }}
                                            >
                                                {sub}
                                            </Link>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <div className="w-50">
                            {secChild.SubCat.map((sub, index) => {
                                if (index >= Math.ceil(secChild.SubCat.length / 2) && index < secChild.SubCat.length && index >= columnMinItemCnt) {
                                    return (
                                        <div className="py-1" key={index}>
                                            <Link
                                                className="nav-link"
                                                to={theme.demo ? '/' : `/shop/${secChild.SecSlug}/${secChild.SubSlug[index]}`}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        document.getElementsByTagName('BODY')[0].click();
                                                    }, 0);
                                                }}
                                            >
                                                {sub}
                                            </Link>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </>
                );

            case 'image':
                return (
                    <div className="boxes-wrapper">
                        {[
                            {
                                imageUrl: 'https://www.drummondgolf.com.au/pub/media/aw_sbb/small_image/brand/titleist_1.png',
                                text: 'Titleist'
                            },
                            {
                                imageUrl:
                                    'https://www.drummondgolf.com.au/pub/media/aw_sbb/small_image/brand/5_callaway-authorised-retailer-logo-not-transparent-black.png',
                                text: 'CallaWay'
                            },
                            {
                                imageUrl: 'https://www.drummondgolf.com.au/pub/media/aw_sbb/small_image/brand/adidas---Black-01.jpg',
                                text: 'ADIDAS'
                            },
                            {
                                imageUrl: 'https://www.drummondgolf.com.au/pub/media/aw_sbb/small_image/brand/Authorised_Dealer_Logo.jpg',
                                text: 'TaylorMade'
                            },
                            {
                                imageUrl: 'https://www.drummondgolf.com.au/pub/media/aw_sbb/small_image/brand/7cobra_1.png',
                                text: 'Cobra'
                            },
                            {
                                imageUrl: 'https://www.drummondgolf.com.au/pub/media/aw_sbb/small_image/brand/logo_1.png',
                                text: 'Mizuno'
                            }
                        ].map((box) => {
                            return (
                                <div className="box-wrapper">
                                    <div className="image-wrapper">
                                        <img src={box.imageUrl} alt={box.text}/>
                                    </div>
                                    <div className="image-text">{box.text}</div>
                                </div>
                            );
                        })}
                    </div>
                );

            default:
        }
    };

    return (
        <>
            <Col md={3} className="col-sec-list">
                {Object.keys(prim).map((secTitle, index) => {
                    const secChild = prim[secTitle];
                    return (
                        <div className="nav-sec-list" key={index}>
                            <Link
                                className={`${activedSec === secTitle ? 'd-flex align-items-center active' : 'd-flex align-items-center'}`}
                                onMouseEnter={() => {
                                    activeSec(secTitle);
                                }}
                                to={theme.demo ? '/' : `/shop/${secChild.SecSlug}`}
                                onClick={() => {
                                    setTimeout(() => {
                                        document.getElementsByTagName('BODY')[0].click();
                                    }, 0);
                                }}
                            >
                                {secTitle}
                                <Icon>arrow_right</Icon>
                            </Link>
                        </div>
                    );
                })}
            </Col>
            <Col md={9}>
                <div className="section-sub-menu w-100">{renderSubCategory()}</div>
            </Col>
        </>
    );
};

export default MegaMenuVertical;
