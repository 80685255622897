import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import './_loader.scss';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 3000,
        color: '#fff'
    }
}));

const Loader = ({ loading, suspended, initializing, bodyCover = true }) => {
    const theme = useTheme();
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    if (!loading && !suspended && !initializing) return null;

    const handleClose = () => {
        setOpen(false);
    };

    switch (theme.loader.name) {
        case 'liquor':
            if (bodyCover) {
                return (
                    <div className="body-cover d-flex justify-content-center align-items-center">
                        <img src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}loading.gif`} alt="loading" />
                    </div>
                );
            } else {
                return (
                    <div className="spinner shop-loading-icon">
                        <img src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}loading.gif`} alt="loading" />
                    </div>
                );
            }
        case 'circle':
            return (
                <div className="body-cover">
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            );
        default:
            return null;
    }
};

const mampStateToProps = (state) => {
    return {
        loading: state.site.loading,
        initializing: state.site.initializing
    };
};

export default connect(mampStateToProps, {})(Loader);
