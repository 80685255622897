import React from 'react';
import Button from 'react-bootstrap/Button';
import useFIBAuth from 'hooks/useFIBAuth';
import './_authBtn.scss';

const { GoogleSignin, FacebookSignin } = useFIBAuth();

export const BtnGoogle = ({ callbefore, btnText, callback }) => {
    const onSingin = () => {
        if (callbefore()) {
            GoogleSignin(callback);
        }
    };

    return (
        <Button variant="default" className={`social-btn google`} onClick={() => onSingin()}>
            <div className="btn-inner">
                <img className="icon" src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}/google.svg`} alt="icon-google" />
                <span>{btnText}</span>
            </div>
        </Button>
    );
};

export const BtnFacebook = ({ callbefore, btnText, callback }) => {
    const onSingin = () => {
        if (callbefore()) {
            FacebookSignin(callback);
        }
    };

    return (
        <Button variant="default" className="social-btn facebook" onClick={() => onSingin()}>
            <div className="btn-inner">
                <img className="icon" src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}/facebook.svg`} alt="icon-facebook" width={30} height={30} />
                <span>{btnText}</span>
            </div>
        </Button>
    );
};
