import React from 'react';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import './_style.scss';

const TopBar = ({ store, siteSettings, tradingHours, isSimpleTopBar }) => {
    const { phone, email, address, state, city, zip, googleLink } = { ...store };
    return (
        <div className="top-info">
            <div className="container">
                {isSimpleTopBar ? (
                    <>
                        <div className="d-flex justify-content-start">
                            <Icon>watch_later</Icon>
                            <div className="pl-1 fz-15">
                                {tradingHours.SummaryShort
                                    ? tradingHours.SummaryShort
                                    : JSON.parse(tradingHours.Summary).map((tradingHours) => {
                                          return tradingHours;
                                      })}
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">
                            <Icon>phone</Icon>
                            <div className="pl-1 fz-15">
                                <a href={`tel:${phone}`}>{phone}</a>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="d-flex justify-content-start">
                            <Icon>phone</Icon>
                            <div className="pl-1 fz-15">
                                <a href={`tel:${phone}`}>{phone}</a>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">
                            <Icon>mail</Icon>
                            <div className="pl-1 fz-15">
                                <a href={`mailto:${email}`}>{email}</a>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">
                            <Icon>watch_later</Icon>
                            <div className="pl-1 fz-15">{tradingHours.Summary}</div>
                        </div>
                        <div className="d-flex justify-content-start">
                            <Icon>room</Icon>
                            <a href={googleLink} target="_blank" rel="noopener noreferrer">
                                <div className="pl-1 fz-15">{`${address}, ${city} ${state} ${zip}`}</div>
                            </a>
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                            <a
                                href={siteSettings.socialChannels.facebook}
                                className="d-flex align-items-center"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icon>facebook</Icon>
                            </a>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const mampStateToProps = (state) => {
    return {
        store: state.store.store,
        tradingHours: state.store.tradingHours,
        siteSettings: state.site.siteSettings
    };
};
export default connect(mampStateToProps)(TopBar);
