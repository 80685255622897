import { INIT_WEB } from '../actions/types';
import _ from 'lodash';

export default (state = {}, action) => {
    switch (action.type) {
        case INIT_WEB:
            return initWeb(state, action);

        default:
            return state;
    }
};

const initWeb = (state, action) => {
    const response = action.payload;
    const storeOptions = response.storeOptions;
    const storesUnion = _.keyBy(_.union(storeOptions.VIC, storeOptions.QLD, storeOptions.TAS, storeOptions.NSW), 'locationId');

    return {
        ...state,
        storeOptions: response.storeOptions,
        store: storesUnion[response.locationId],
        tradingHours: response.tradingHours
    };
};
