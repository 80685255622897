import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollToTop from 'components/ScrollToTop';
import Icon from '@material-ui/core/Icon';
// import Popup from 'components/Popup';
import './_footer.scss';
import theme from 'theme';

const Footer = ({ loading, isMobile, entryStatus, footer, tradingHours, store, siteSettings }) => {
    if (loading || !entryStatus) {
        return null;
    }

    if (isMobile) return <div className="py-5"></div>;

    if (!footer || !tradingHours) return null;

    const { paragraph } = { ...footer };
    const { phone, email, address, state, city, zip, googleLink } = { ...store };
    const tradingHoursArray = JSON.parse(tradingHours.Summary);

    return (
        <>
            <footer className="footer bg-dark">
                <div className="container">
                    <Row className="d-none">
                        <Col md={12} sm={12} className="text-center">
                            <img
                                src={`${process.env.REACT_APP_ASSETS_DOMAIN}assets/img/site/under-18-notice.png`}
                                alt="under-18-notice"
                                width={`160px`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="footer-wrapper">
                                <div className="about">
                                    <div className="col-wrapper">
                                        <div className="title">{paragraph[0].title}</div>
                                        <div className="body">{paragraph[0].body}</div>
                                        <div className="body">
                                            <a
                                                href={siteSettings.socialChannels.facebook}
                                                className="d-flex align-items-center"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Icon>facebook</Icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="links">
                                    <div className="col-wrapper">
                                        <div className="title">QUICK LINKS</div>
                                        <div className="body">
                                            <Link to="/">Home</Link>
                                        </div>
                                        {entryStatus.find((entry) => entry.slug === 'specials') ? (
                                            <div className="body">
                                                <Link to="/specials">Specials</Link>
                                            </div>
                                        ) : null}

                                        <div className="body">
                                            <Link to="/about">About Us</Link>
                                        </div>
                                        <div className="body">
                                            <Link to="/contact">Contact Us</Link>
                                        </div>
                                        {theme.footer.extraLink.map(function(e){
                                            return (
                                                <div className="body">
                                                    <Link to={e[0]}>{e[1]}</Link>
                                                </div>
                                            );
                                        })}
                                        {/* <div className="body">
                                            <Link to="/infinity-rewards">Infinity Rewards</Link>
                                        </div> */}
                                        {/*<div className="body">*/}
                                        {/*    <Link to="/mobile">Mobile User Guide</Link>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="hours">
                                    <div className="col-wrapper">
                                        <div className="title">Hours</div>
                                        {tradingHoursArray.map((hours, index) => {
                                            return <div className="body" key={index}>{hours}</div>;
                                        })}
                                    </div>
                                </div>
                                <div className="contact">
                                    <div className="col-wrapper">
                                        <div className="title">CONTACT US</div>
                                        <div className="body d-flex justify-content-start align-items-center">
                                            <Icon>room</Icon>
                                            <a href={googleLink} target="_blank" rel="noopener noreferrer">
                                                <div className="pl-1 fz-15">{`${address}, ${city} ${state} ${zip}`}</div>
                                            </a>
                                        </div>
                                        <div className="body d-flex justify-content-start">
                                            <Icon>phone</Icon>
                                            <div className="pl-1 fz-15">
                                                <a href={`tel:${phone}`}>{phone}</a>
                                            </div>
                                        </div>{' '}
                                        <div className="body d-flex justify-content-start">
                                            <Icon>mail</Icon>
                                            <div className="pl-1 fz-15">
                                                <a href={`mailto:${email}`}>{email}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <hr style={{ borderTopColor: '#808080' }} />
                            <div className="footer-bottom">
                                {entryStatus.find((entry) => entry.slug === 'privacy-policy') ? (
                                    <div className="mr-3 d-inline">
                                        <Link to="/privacy-policy">
                                            Privacy Policy
                                        </Link>
                                    </div>
                                ) : null}
                                {entryStatus.find((entry) => entry.slug === 'terms-conditions') ? (
                                    <div className="mr-3 d-inline">
                                        <Link to="/terms-conditions">
                                            Terms & Conditions
                                        </Link>
                                    </div>
                                ) : null}
                                {theme.footer.showDesigner ? (
                                    <div>
                                        <a
                                            className="copyright-text text-white text-underline"
                                            href="https://marketinggroup.com.au"
                                            rel="noopener noreferrer"
                                            title="This website is made by Media Marketing Group"
                                            target="_blank"
                                        >
                                            Designed by MMG
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </footer>

            <ScrollToTop />
            {/* <Popup /> */}
            {/* <SnackBar /> */}
        </>
    );
};

const mampStateToProps = (state) => {
    return {
        loading: state.site.loading,
        entryStatus: state.nav.entryStatus,
        footer: state.nav.footer,
        tradingHours: state.store.tradingHours,
        store: state.store.store,
        siteSettings: state.site.siteSettings
    };
};

export default connect(mampStateToProps, {})(Footer);
