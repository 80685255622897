import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import useAuth from 'hooks/useAuth';

const SignInStatus = ({ user }) => {
    const [onLoggingOut, setOnLoggingOut] = useState(false);
    const { signOut, modalToggle } = useAuth();

    const handleSignOut = () => {
        if (onLoggingOut) return;
        setOnLoggingOut(true);
        signOut();
    };

    const renderUser = () => {
        const userName = !user.firstName && !user.lastName ? 'My Profile' : `${user.firstName} ${user.lastName}`;
        return (
            <>
                <div className="icon d-flex align-items-center mr-3">
                    <div>
                        <Link to="/account">
                            <img src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}icon-user.svg`} alt="Steve's Liquor User Account icon" />
                        </Link>
                    </div>
                </div>
                <div className="details">
                    <h6 className="title">
                        <Link to="/account"> {userName} </Link>
                    </h6>
                    <small>
                        <Link to="#" onClick={() => handleSignOut()}>
                            {onLoggingOut ? 'Logging Out...' : 'Logout'}
                        </Link>
                    </small>
                </div>
            </>
        );
    };

    const renderGuest = () => {
        return (
            <>
                <div className="icon d-flex align-items-center mr-3">
                    <img src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}icon-user.svg`} alt="Steve's Liquor Store Login icon" />
                </div>
                <div className="details">
                    <h6 className="title">Login to Edit Profile</h6>
                    <small>
                        <Link to="#" onClick={() => modalToggle('login', true)}>
                            Login
                        </Link>
                        <span className="mx-2">or</span>
                        <Link to="#" onClick={() => modalToggle('signup', true)}>
                            Register
                        </Link>
                    </small>
                </div>
            </>
        );
    };

    if (user) {
        return renderUser();
    } else {
        return renderGuest();
    }
};

export default connect(null, {})(SignInStatus);
