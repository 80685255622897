export const saveToken = (auth) => {
    if (!auth) {
        return;
    }

    const tokenRefreshed = auth.tokenRefreshed;
    const tokens = auth.tokens;

    if (tokenRefreshed) {
        Object.keys(tokens).forEach((k) => {
            const v = tokens[k];
            localStorage.setItem(k, v);
        });
    }
};
