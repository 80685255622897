import { 
    UPDATE_CART,
    UPDATE_ADJ,
    ALERT,
    UPDATE_USER_INFO
} from '../actions/types';

export default (state={}, action) => {
    switch(action.type){
        case UPDATE_CART:
            return updateCart(state, action);

        case UPDATE_ADJ:
            return updateAdj(state, action);

        case ALERT:
            return alert(state, action);

        case UPDATE_USER_INFO:
            return updateUserInfo(state, action);

        default:
            return state;
    }
}

const updateCart = (state, action) => {
    const response = action.payload;
    return {
        ...state,
        alert: response.message
    }
}

const updateAdj = (state, action) => {
    const response = action.payload;
    if(response.message){
        return {
            ...state,
            alert: response.message
        }
    }else{
        return {
            ...state,
        }
    }
}

const alert = (state, action) => {
    return {
        ...state,
        alert: action.payload
    }
}

const updateUserInfo = (state, action) => {
    const response = action.payload;
    return {
        ...state,
        alert: response.message
    }
}