import { INIT_WEB } from '../actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case INIT_WEB:
            return initWeb(state, action);

        default:
            return state;
    }
};

const initWeb = (state, action) => {
    const response = action.payload;

    return {
        ...state,
        navMenu: response.navMenu,
        navSpecials: response.navSpecials,
        entryStatus: response.entryStatus,
        footer: response.footer
    };
};
