import moment from 'moment';

export function isValidDate(date, format='DD/MM/YYYY'){
    return moment(date, format, true).isValid();
}

export function getMoment(date=null, format=null){
    if(!date){
        return moment();
    }else{
        return moment(date, format);
    }
}