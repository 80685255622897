// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyDt-9U919fgawD5YwLgb-15-NYUWqLU9-8',
    authDomain: 'ilg-fib.firebaseapp.com',
    databaseURL: 'https://ilg-fib.firebaseio.com',
    projectId: 'ilg-fib',
    storageBucket: 'ilg-fib.appspot.com',
    messagingSenderId: '37577710793',
    appId: '1:37577710793:web:350083865d0e074eaa4118',
    measurementId: 'G-59KEWSXH36'
};

firebase.initializeApp(firebaseConfig);

export default () => {
    const GoogleSignin = async (callback) => {
        try {
            let provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');
            const response = await firebase.auth().signInWithPopup(provider);
            console.log(response);
            callback(response);
        } catch (e) {
            return e;
        }
    };

    const FacebookSignin = async (callback) => {
        try {
            let provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope('email');
            const response = await firebase.auth().signInWithPopup(provider);
            callback(response);
        } catch (e) {
            return e;
        }
    };

    const signout = async () => {
        try {
            await firebase.auth().signOut();
            console.log('sign out...');
        } catch (e) {
            console.log(e);
        }
    };

    return { GoogleSignin, FacebookSignin, signout };
};
