import React, { useState } from 'react';
import history from 'toolbox/history';

const Search = () => {
    const [term, setTerm] = useState();

    const handleSearch = async () => {
        if (term) {
            history.push(`/shop?search=${window.encodeURIComponent(term)}`);
        }
    };

    return (
        <>
            <input
                className="searchPhrase form-control search-text"
                type="search"
                placeholder="Search for product"
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && e.target.value) {
                        e.target.blur();
                        handleSearch(e.target.value);
                    }
                }}
                onChange={(e) => {
                    setTerm(e.target.value);
                }}
            />
            <div className="input-group-append" onClick={() => handleSearch(term)}>
                <span className="input-group-text">
                    <img
                        className="cursor-pointer"
                        src={`${process.env.REACT_APP_ASSETS_ICON_DOMAIN}icon-search.svg`}
                        height="16px"
                        width="16px"
                        alt="Steve's Liquor Store Search icon"
                    />
                </span>
            </div>
        </>
    );
};

export default Search;
