import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from 'toolbox/history';
import Header from 'components/header';
import Footer from 'components/footer';
import BottomNav from 'components/BottomNav';
import Loader from 'components/Loader';
import AppContext from 'context/app-context';
import useInitial from 'hooks/useInitial';
import ErrorBoundary from 'toolbox/ErrorBoundary';
import Popup from 'components/Popup';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../theme';

const OnRouterUpdate = lazy(() => import('toolbox/OnRouterUpdate'));
const Home = lazy(() => import('page/home'));
const Shop = lazy(() => import('page/shop'));
const Product = lazy(() => import('page/product'));
const Cart = lazy(() => import('page/cart'));
const CustomertInfo = lazy(() => import('page/customer_info'));
const Delivery = lazy(() => import('page/delivery'));
const Payment = lazy(() => import('page/payment'));
const CustomerOrder = lazy(() => import('page/view/CustomerOrder'));
const Account = lazy(() => import('page/account'));
const EditProfile = lazy(() => import('page/account/edit_profile'));
const ResetPassword = lazy(() => import('page/account/reset_password'));
const EditAddress = lazy(() => import('page/account/edit_address'));
const PageStatic = lazy(() => import('page/static'));
const Contact = lazy(() => import('page/contact'));
const AboutUs = lazy(() => import('page/about'));
const Catalogue = lazy(() => import('page/catalogue'));
const NotFound = lazy(() => import('toolbox/_404'));

const APP = () => {
    const isMobile = React.useContext(AppContext).isMobile;
    const [initWeb] = useInitial();
    const [openPopup, setOpenPopup] = useState(true);

    useEffect(() => {
        initWeb();

        //eslint-disable-next-line
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Loader />
            <Router history={history}>
                <Header isMobile={isMobile} />
                <BottomNav isMobile={isMobile} />

                <Suspense fallback={<Loader suspended={true} />}>
                    <OnRouterUpdate>
                        <Switch>
                            <Route path={`/`} exact render={(props) => <Home {...props} isMobile={isMobile} />} />
                            <Route
                                path={[
                                    `/shop`,
                                    `/shop/p/:pagination?`,
                                    `/shop/:sec`,
                                    `/shop/:sec/p/:pagination?`,
                                    `/shop/:sec/:sub`,
                                    `/shop/:sec/:sub/p/:pagination?`,
                                    `/wishlist`,
                                    `/wishlist/p/:pagination?`,
                                    `/wishlist/:sec`,
                                    `/wishlist/:sec/p/:pagination?`,
                                    `/wishlist/:sec/:sub`,
                                    `/wishlist/:sec/:sub/p/:pagination?`
                                ]}
                                exact
                                render={(props) => <Shop {...props} />}
                            />
                            <Route path={`/product/:slug`} exact render={(props) => <Product {...props} isMobile={isMobile} />} />
                            <Route path={`/cart`} exact render={(props) => <Cart {...props} />} />
                            <Route path={`/view/order/:orderNumber`} exact render={(props) => <CustomerOrder {...props} />} />
                            <Route path={`/account`} exact render={(props) => <Account {...props} />} />
                            <Route path={`/account/edit-profile`} exact render={(props) => <EditProfile {...props} />} />
                            <Route path={`/account/reset-password`} exact render={(props) => <ResetPassword {...props} />} />
                            <Route path={`/account/address/:addrId?`} exact render={(props) => <EditAddress {...props} />} />
                            <Route
                                path={[
                                    '/privacy-policy',
                                    '/liquor-licences',
                                    '/terms-conditions',
                                    '/delivery-shipping',
                                    '/mobile',
                                    '/rewards',
                                    '/infinity-rewards',
                                    '/infinity-rewards-terms-and-conditions'
                                ]}
                                exact
                                render={(props) => <PageStatic {...props} />}
                            />
                            <Route
                                path={`/checkout/customer-info`}
                                exact
                                render={(props) => (
                                    <ErrorBoundary redirect="/cart">
                                        <CustomertInfo {...props} />
                                    </ErrorBoundary>
                                )}
                            />
                            <Route
                                path={`/checkout/delivery`}
                                exact
                                render={(props) => (
                                    <ErrorBoundary redirect="/cart">
                                        <Delivery {...props} />
                                    </ErrorBoundary>
                                )}
                            />
                            <Route
                                path={`/checkout/payment`}
                                exact
                                render={(props) => (
                                    <ErrorBoundary redirect="/checkout/payment">
                                        <Payment {...props} />
                                    </ErrorBoundary>
                                )}
                            />
                            <Route path={`/contact`} exact render={(props) => <Contact {...props} />} />
                            <Route path={`/about`} exact render={(props) => <AboutUs {...props} />} />
                            <Route path={`/specials`} exact render={(props) => <Catalogue {...props} />} />
                            <Route component={NotFound} />
                        </Switch>
                    </OnRouterUpdate>
                </Suspense>
                <Footer isMobile={isMobile} />
                <Popup open={openPopup} callbackOpenPopup={setOpenPopup} />
            </Router>
        </ThemeProvider>
    );
};

export default APP;
