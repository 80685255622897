import { FETCH_ENTRY } from 'actions/types';
import { getPathUrl } from 'toolbox/UrlHelper';

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_ENTRY:
            return dispatchEntry(state, action);

        default:
            return state;
    }
};

const dispatchEntry = (state, action) => {
    const payload = action.payload;

    let _state = {
        ...state,
        [payload.entryName]: payload.entry,
        seo: payload.entry?.seo
    };

    if (payload.entryName === 'entryShop') {
        _state.shopIndexPathUrl = getPathUrl();
    }
    if (payload.entryName === 'entryStatic') {
        _state[payload.entry.slug] = payload.entry;
    }

    return _state;
};
