import React from 'react';
import './_checkbox.scss';

const Checkbox = ({ text, checked, onChange, isInvalid = false, errorMessage = null }) => {
    return (
        <>
            <label className={`checkbox-container ${isInvalid ? 'is-invalid mb-0' : ''}`}>
                <span>{text}</span>
                <input
                    type="checkbox"
                    className="form-control"
                    value={checked}
                    checked={checked}
                    onChange={onChange}
                />
                <span className="checkmark"></span>
            </label>
            {errorMessage ? <div className="text-danger">{errorMessage}</div> : null}
        </>
    );
};

export default Checkbox;
