import { useDispatch } from 'react-redux';
import useToast from 'hooks/useToast';
import { saveToken } from 'toolbox/Token';
import api from 'apis/api';
import { UPDATE_CART, UPDATE_USER_INFO } from 'actions/types';

export default () => {
    const [toastMessage] = useToast();
    const dispatch = useDispatch();

    const updateCart = async ({ qty, purchasableId, couponCode, postBody }) => {
        if (typeof qty !== 'undefined') {
            postBody = { qty, purchasableId };
        } else if (couponCode) {
            postBody = { couponCode };
        }
        let response = {};

        console.log(postBody);
        
        try {
            response = await api.post('api/v1/update/cart', postBody);
        } catch (e) {
            toastMessage({ type: 'error', message: { Error: 'Error Occured. Please try again later' } });
            return false;
        }

        dispatch({ type: UPDATE_CART, payload: response.data });
        if (response.data.user) dispatch({ type: UPDATE_USER_INFO, payload: response.data });
        if (response.data.auth) saveToken(response.data.auth);

        toastMessage(response.data.message);
        return response.data.message?.type;
    };

    return [updateCart];
};
