import { 
    INIT_WEB,
    LOGIN,
    // LOGOUT,
    SIGNUP,
    UPDATE_USER_INFO
} from '../actions/types';

export default (state={}, action) => {
    switch(action.type){
        case INIT_WEB:
            return initWeb(state, action);

        case LOGIN:
            return login(state, action);

        // case LOGOUT:
        //     return logout(state, action);

        case SIGNUP:
            return signup(state, action);

        case UPDATE_USER_INFO:
            return updateUserInfo(state, action);

        default:
            return state;
    }
}

const initWeb = (state, action) => {
    const response = action.payload;
    return {
        ...state,
        user: response.user
    }
}

const login = (state, action) => {
    const response = action.payload;
    return {
        ...state,
        loginError: response.error || null
    }
    
    // const response = action.payload;
    // if(response.error){
    //     return {
    //         ...state,
    //         loginError: response.error
    //     }
    // }else{
    //     return {
    //         ...state,
    //         loginError: null,
    //         user: response.user,
    //     }
    // }
}

// const logout = (state, action) => {
//     const response = action.payload;
//     return {
//         ...state,
//         user: response.user,
//     }
// }

const signup = (state, action) => {
    const response = action.payload;
    return {
        ...state,
        signupError: response.error,
        user: response.user
        // orderId: response.orderId,
    }
}

const updateUserInfo = (state, action) => {
    const response = action.payload;
    return {
        ...state,
        user: response.user
    }
}
