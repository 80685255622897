import { 
    MODAL_TOGGLE
} from '../actions/types';

export default (state={}, action) => {
    switch(action.type){
        case MODAL_TOGGLE:
            return modalToggle(state, action);

        default:
            return state;
    }
}

const modalToggle = (state, action) => {
    const payload = action.payload;
    return {
        ...state,
        source: payload.source,
        status: payload.status
    }
}